import DomainImage from '@/components/_common/DomainImage/DomainImage';
import Task from '@/components/_v2/Task/Task';
import { useCanvas } from '@/context/CanvasContextProvider';
import { TaskContextProvider } from '@/context/TaskContextProvider';
import TaskPlaceholderImg from '@/public/task-placeholder.png';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useRouter } from 'next/navigation';

type Props = {
  task: any;
  refetchTaskWidget: () => void;
};

function TaskWidgetTask({ task, refetchTaskWidget }: Props) {
  const router = useRouter();
  const {
    setShowCanvas,
    setCanvasContent,
    setShowCanvasHeader,
    setIsFullScreen,
  } = useCanvas();

  const handleClose = () => {
    setShowCanvas(false);
    setShowCanvasHeader(true);
    setIsFullScreen(false);
  };

  const handleClick = () => {
    const taskMeta = JSON.parse(task.meta);
    if (taskMeta?.type === 'url') {
      router.push(taskMeta.url);
    } else {
      setCanvasContent(
        <TaskContextProvider>
          <Task
            task={task}
            refetchTodo={refetchTaskWidget}
            onClose={handleClose}
          />
        </TaskContextProvider>
      );
      setShowCanvasHeader(false);
      setIsFullScreen(true);
      setShowCanvas(true);
    }
  };

  if (task.status === 'complete') return null;

  return (
    <Card
      variant="info"
      className="hover hoverWhite"
      onClick={handleClick}
      data-testid={task.name}
      sx={{ height: 'fit-content' }}
    >
      <CardActionArea>
        <CardHeader
          avatar={
            <CardMedia
              src={task.image || TaskPlaceholderImg.src}
              component="img"
              onError={(e: any) => {
                e.target.src = TaskPlaceholderImg.src;
              }}
            />
          }
          title={task.name}
          action={
            <IconButton color="primary" size="small" sx={{ m: 1 }}>
              <ChevronRight />
            </IconButton>
          }
        />
        {task.company && (
          <CardContent
            sx={{
              m: '0 !important',
              p: '0 8px 8px 8px !important',
              width: '100% !important',
            }}
          >
            <Stack direction="row" gap={1} alignItems={'center'}>
              <Stack direction="row" gap={0.5} alignItems={'center'}>
                {task.domain !== 'invite' && task.domainImageUrl !== null && (
                  <DomainImage
                    src={task.domainImageUrl}
                    alt={task.domain}
                    size="sm"
                  />
                )}
                <Tooltip title={task.company}>
                  <DomainImage
                    src={
                      task.domain === 'invite'
                        ? task.image
                        : task.companyImageUrl
                    }
                    alt={task.company}
                    size="sm"
                  />
                </Tooltip>
              </Stack>
              <Typography variant="body2" sx={{ opacity: 0.6 }}>
                asked for this
              </Typography>
            </Stack>
          </CardContent>
        )}
      </CardActionArea>
    </Card>
  );
}

export default TaskWidgetTask;
