import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

function TaskLoader() {
  return (
    <Card variant="infoLarge" elevation={0}>
      <CardHeader
        avatar={
          <Skeleton
            color="white"
            variant="rounded"
            sx={{
              width: '64px',
              height: '64px',
              borderRadius: '8px',
            }}
          />
        }
        title={
          <Skeleton color="white" variant="text" width={150} height={24} />
        }
      />
      <CardContent>
        <Stack flexDirection="row">
          <Skeleton color="white" variant="text" width={120} height={21} />
          <Skeleton color="white" variant="text" width={90} height={21} />
        </Stack>
      </CardContent>
    </Card>
  );
}

export default TaskLoader;
