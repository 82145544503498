import Stack from '@mui/material/Stack';
import { Task } from '../taskWidgetTypes';
import TaskWidgetTask from './Task';

type Props = {
  tasks: Task[];
  refetch: () => void;
};

const TaskListUser = ({ tasks, refetch }: Props) => {
  const pendingTasks = tasks.filter((task) => task.status !== 'complete');

  if (pendingTasks.length === 0) return null;

  return (
    <Stack gap={1} sx={{ width: '100%', pr: 1 }}>
      {pendingTasks.map((task) => (
        <TaskWidgetTask
          key={task.taskId}
          task={task}
          refetchTaskWidget={refetch}
        />
      ))}
    </Stack>
  );
};

export default TaskListUser;
