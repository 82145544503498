import DomainImage from '@/components/_common/DomainImage/DomainImage';
import TaskGroupV2 from '@/components/_v2/TaskGroup/TaskGroupV2';
import { useCanvas } from '@/context/CanvasContextProvider';
import { TaskContextProvider } from '@/context/TaskContextProvider';
import { TaskGroupContextProvider } from '@/context/TaskGroupContextProvider';
import TaskPlaceholderImg from '@/public/task-placeholder.png';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

type Props = {
  taskGroup: any;
  refetchTaskWidget: () => void;
};

function TaskWidgetTaskGroup({ taskGroup, refetchTaskWidget }: Props) {
  const {
    setShowCanvas,
    setCanvasContent,
    setShowCanvasHeader,
    setIsFullScreen,
  } = useCanvas();
  const completeTasks = taskGroup.tasks?.filter(
    (task: any) => task.status === 'complete'
  );
  const progressValue = (completeTasks.length / taskGroup.tasks.length) * 100;
  const domainImageUrl = taskGroup.tasks[0].domainImageUrl;

  const handleClose = () => {
    setShowCanvas(false);
    setShowCanvasHeader(true);
    setIsFullScreen(false);
    refetchTaskWidget();
  };

  const handleClick = () => {
    //make canvas open on top of the current page
    setCanvasContent(
      <TaskContextProvider>
        <TaskGroupContextProvider>
          <TaskGroupV2 taskGroup={taskGroup} onClose={handleClose} />
        </TaskGroupContextProvider>
      </TaskContextProvider>
    );
    setShowCanvasHeader(false);
    setIsFullScreen(true);
    setShowCanvas(true);
  };

  if (taskGroup.tasks.every((task: any) => task.status === 'complete'))
    return null;

  return (
    <Card
      variant="info"
      className="hover hoverWhite"
      onClick={handleClick}
      sx={{ height: 'fit-content' }}
    >
      <CardActionArea>
        <CardHeader
          avatar={
            <CardMedia
              src={taskGroup.image || TaskPlaceholderImg.src}
              component="img"
              onError={(e: any) => {
                e.target.src = TaskPlaceholderImg.src;
              }}
            />
          }
          title={taskGroup.title}
          action={
            <IconButton color="primary" size="small" sx={{ m: 1 }}>
              <ChevronRight />
            </IconButton>
          }
        />
        <CardContent
          sx={{
            m: '0 !important',
            p: '0 8px 8px 8px !important',
            width: '100% !important',
          }}
        >
          <Stack
            sx={{
              width: '100%',
              flexDirection: 'row',
              gap: 0.5,
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {domainImageUrl !== null && (
              <DomainImage
                src={domainImageUrl}
                alt={taskGroup.title}
                size="sm"
              />
            )}
            <Stack sx={{ flexDirection: 'row', flex: 1, px: 0.5 }}>
              <Box sx={{ width: '100%' }}>
                <LinearProgress
                  variant="determinate"
                  value={progressValue}
                  color={progressValue === 100 ? 'success' : 'warning'}
                />
              </Box>
            </Stack>
            <Typography
              variant="caption"
              pr={1}
              sx={{
                color: progressValue === 100 ? 'success.main' : 'warning.main',
              }}
            >
              {completeTasks.length} of {taskGroup.tasks.length}
            </Typography>
          </Stack>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default TaskWidgetTaskGroup;
