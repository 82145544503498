import { gql } from '@apollo/client';

export const GET_USER = gql`
  query GetUser {
    user {
      userId
      prettyUserId
      name
      givenName
      familyName
      email
      phone
    }
  }
`;

export const GET_USER_TRANSACTIONS = gql`
  query GetUser {
    user {
      transactions {
        transactionId
        type
        status
        imageUrl
        address
        shortAddress
        created
        userStatus
        property {
          line1
          line2
          town
          postcode
          address
          uprn
        }
      }
    }
  }
`;

export const GET_COMPANY_INVITE = gql`
  query GetCompanyInvite($inviteId: String!, $userId: String) {
    companyInvite(inviteId: $inviteId, userId: $userId) {
      invite {
        requestId
        companyId
        status
        name
        email
        link
        transactionId
        meta {
          board
          agreedPrice
          pricingType
          maxPrice
          minPrice
          role
          uprn
          feeDescription
          rexListingId
          propertyName
          propertyLine1
          propertyLine2
          propertyPostcode
          propertyTown
          transactionType
          contracts
          assigneeId
          verifyId
          assigneeFirstName
          assigneeLastName
          assigneeEmail
          offerAmount
          offerAccepted
          linkedTransactionId
          metClients
        }
      }
      company {
        name
        slug
        logoUrl
        privacyPolicyLink
        brandColour
      }
      existingTransactions {
        transactionId
        type
        property {
          propertyName
          propertyAddress
          lineOne
          lineTwo
          city
          postcode
          uprn
        }
      }
      customer {
        customerId
        privacyPolicy {
          companyId
        }
      }
      privacyPolicyAccepted
      companyOnTransaction
    }
  }
`;

export const GET_TRANSACTION = gql`
  query transaction($transactionId: String!, $forms: [String]) {
    transaction(transactionId: $transactionId, forms: $forms) {
      transactionId
      linkedTransactionId
      prettyTransactionId
      type
      status
      propertyName
      propertyAddress
      imageUrl
      property {
        propertyName
        propertyAddress
        lineOne
        lineTwo
        city
        postcode
      }
      views {
        people {
          peopleWidget {
            participantId
            role
            verified
            firstName
            lastName
            email
            customerId
            requests {
              status
            }
            currentUser
          }
        }
      }
    }
  }
`;

export const GET_PUBLIC_TRANSACTION = gql`
  query transactionPublic($transactionId: String!) {
    transactionPublic(transactionId: $transactionId) {
      transactionId
      linkedTransactionId
      prettyTransactionId
      type
      status
      propertyName
      propertyAddress
      imageUrl
      property {
        propertyName
        propertyAddress
        lineOne
        lineTwo
        city
        postcode
      }
    }
  }
`;

export const GET_COMPANY_TRANSACTION = gql`
  query GetCompanyTransaction($transactionId: String!, $companyId: String!) {
    companyTransaction(transactionId: $transactionId, companyId: $companyId) {
      transaction {
        transactionId
        type
        property {
          propertyName
          lineOne
          lineTwo
          city
          postcode
        }
        participants {
          firstName
          lastName
          email
          customerId
          participantId
          role
          verified
          requests {
            requestParticipantId
            email
            firstName
            lastName
            target
            status
            link
          }
          currentUser
        }
      }
      company {
        name
        slug
        logoUrl
        privacyPolicyLink
        brandColour
      }
      customer {
        customerId
        verifyIdStatus
      }
      outstandingContracts
      invitesComplete
      participantsAwaitingSignup {
        firstName
        lastName
      }
      participantsAwaitingVerifyId {
        firstName
        lastName
      }
      participantsAwaitingContract {
        firstName
        lastName
      }
      identityComplete
      verifyId
    }
  }
`;

export const GET_CONTRACT = gql`
  query GetContract($contractId: String!) {
    contract(contractId: $contractId) {
      contractId
      text
      signed
      companyId
      company {
        name
        slug
        logoUrl
        brandColour
      }
      template
      meta {
        htmlCustomerAddress
        htmlTransactionAddress
        companyName
        customerLegalName
        estateAgentKeys
        estateAgentBoard
      }
    }
  }
`;

export const GET_PARTICIPANT_INVITE = gql`
  query GetParticipantInvite($participantId: String!) {
    participant(participantId: $participantId) {
      participant {
        participantId
        firstName
        lastName
        email
        addedBy
        customerId
      }
      transaction {
        transactionId
        property {
          propertyId
          lineOne
          lineTwo
          city
          postcode
          propertyName
        }
        type
      }
      companies {
        name
        logoUrl
        slug
        privacyPolicyLink
      }
    }
  }
`;

export const GET_FILE = gql`
  query GetFile($reference: String!) {
    getFile(reference: $reference) {
      name
      url
    }
  }
`;

export const GET_PROPERTY_FORM = gql`
  query propertyForm($transactionId: String!, $formId: String!) {
    propertyForm(transactionId: $transactionId, formId: $formId) {
      formId
      formTitle
      formValues
      formStatus
      formPages {
        pageId
        pageType
        pageBackgroundImage
        pageTrigger {
          id
          input
          value
        }
        pageComponents {
          componentId
          componentLabel
          componentGuidance
          componentTrigger {
            id
            input
            value
          }
          componentInputs {
            name
            type
            label
            rank
            placeholder
            iconRight
            iconLeft
            config
            options {
              label
              id
            }
          }
        }
        footerComponents {
          componentId
          componentLabel
          componentGuidance
          componentInputs {
            url
            target
            iconLeft
            iconRight
            label
            name
            rank
            type
          }
        }
      }
    }
  }
`;

export const GET_FORM = gql`
  query form($formTemplateId: String!) {
    form(formTemplateId: $formTemplateId) {
      formId
      formTitle
      formValues
      formStatus
      formPages {
        pageId
        pageType
        pageBackgroundImage
        pageTrigger {
          id
          input
          value
        }
        pageComponents {
          componentId
          componentLabel
          componentGuidance
          componentTrigger {
            id
            input
            value
          }
          componentInputs {
            name
            type
            label
            rank
            placeholder
            iconRight
            iconLeft
            config
            options {
              label
              id
            }
          }
        }
        footerComponents {
          componentId
          componentLabel
          componentGuidance
          componentInputs {
            url
            target
            iconLeft
            iconRight
            label
            name
            rank
            type
          }
        }
      }
    }
  }
`;

export const GET_PROPERTY_PACK_STATUS = gql`
  query propertyPackStatus($transactionId: String!) {
    propertyPackStatus(transactionId: $transactionId) {
      materialFacts {
        ownership
        additionalInformation
        alterationsAndChanges
        boundaries
        centralHeating
        connectedUtilities
        councilTax
        delayFactors
        disputesAndComplaints
        energyEfficiency
        environmentalIssues
        parking
        insurance
        listingAndConservation
        notices
        otherIssues
        rightsAndInformalArrangements
        specialistIssues
        typeOfConstruction
        waterAndDrainage
        consumerProtectionRegulationsDeclaration
      }
      additionalLegalInfo {
        sellersCapacity
        legalBoundaries
        servicesCrossing
        energyAndMeters
        guaranteesWarrantiesAndIndemnityInsurances
        occupiers
        completionAndMoving
        confirmationOfAccuracyByOwners
      }
    }
  }
`;

export const GET_PROPERTY_FORM_RESPONSES = gql`
  query propertyFormResponses($transactionId: String!, $section: String) {
    propertyFormResponses(transactionId: $transactionId, section: $section) {
      title
      content {
        question
        key
        answers
      }
    }
  }
`;

export const GET_TASKS = gql`
  query GetTasks {
    tasks {
      tasks {
        taskId
        name
        description
        image
        domain
        key
        meta
        listener
        predecessor
        status
      }
      taskGroups {
        taskGroupId
        name
        description
        image
        tasks {
          taskId
          name
          description
          image
          domain
          key
          meta
          listener
          predecessor
          status
        }
      }
    }
  }
`;

export const GET_INVITE = gql`
  query invite($inviteId: String!, $userId: String) {
    invite(inviteId: $inviteId, userId: $userId) {
      invite {
        email
        firstName
        lastName
        name
        companyId
        status
        type
        requestId
        link
        transactionId
        meta {
          companyName
          propertyName
          propertyLine1
          propertyLine2
          propertyTown
          propertyPostcode
          uprn
        }
      }
      transactions {
        transactionId
        type
        property {
          propertyName
          lineOne
          propertyAddress
          uprn
        }
      }
      company {
        privacyPolicyLink
        logoUrl
        slug
        brandColour
      }
    }
  }
`;

export const GET_TASK_GROUP = gql`
  query taskGroup($taskGroupId: String!) {
    taskGroup(taskGroupId: $taskGroupId) {
      taskGroupId
      name
      description
      image
      tasks {
        taskId
        name
        description
        image
        domain
        key
        meta
        listener
        predecessor
        status
      }
    }
  }
`;

export const TEAM_SUMMARY = gql`
  query teamSummary {
    teamSummary {
      name
      slug
      service
      status
      logoUrl
    }
  }
`;

export const TRANSACTION_SUMMARY = gql`
  query transactionsSummary {
    transactionsSummary {
      transactionId
      linkedTransactionId
      type
      status
      uprn
      created
      people
      propertyName
      propertyAddress
      imageUrl
    }
  }
`;

export const GET_TRANSACTION_PARTICIPANT_INFO = gql`
  query transaction($transactionId: String!) {
    transaction(transactionId: $transactionId) {
      transactionId
      type
      property {
        propertyName
        propertyAddress
      }
      participants {
        participantId
        currentUser
        hasInfo
        role
        firstName
        lastName
        dateOfBirth
        address {
          line1
          line2
          town
          postcode
        }
        email
        customer {
          firstName
          lastName
          dateOfBirth
          address {
            lineOne
            lineTwo
            city
            postcode
          }
        }
      }
    }
  }
`;

export const GET_TRANSACTION_BIOMETRIC_ID = gql`
  query transaction($transactionId: String!) {
    transaction(transactionId: $transactionId) {
      transactionId
      type
      participants {
        participantId
        currentUser
        firstName
        lastName
        verified
        customer {
          customerId
        }
      }
    }
  }
`;

export const TRANSACTION_CONTRACT_SUMMARY = gql`
  query transactionContractSummary(
    $transactionId: String!
    $companyId: String!
  ) {
    transactionContractSummary(
      transactionId: $transactionId
      companyId: $companyId
    ) {
      contracts {
        contractId
        name
        participants {
          participantId
          customerId
          firstName
          lastName
          signed
          currentUser
        }
      }
      company {
        name
        slug
        logoUrl
        brandColour
      }
    }
  }
`;

export const TRANSACTION_PROPERTY_DATA = gql`
  query propertyData(
    $transactionId: String!
    $sections: [String!]!
    $schema: String!
  ) {
    propertyData(
      transactionId: $transactionId
      sections: $sections
      schema: $schema
    ) {
      title
      entries {
        title
        section
        value
      }
    }
  }
`;

export const TRANSACTION_PROPERTY_PUBLIC_DATA = gql`
  query propertyPublicData(
    $transactionId: String!
    $sections: [String!]!
    $schema: String!
  ) {
    propertyPublicData(
      transactionId: $transactionId
      sections: $sections
      schema: $schema
    ) {
      title
      entries {
        title
        section
        value
      }
    }
  }
`;

export const GET_TRANSACTION_TITLES = gql`
  query registerExtractByTransaction($transactionId: String!) {
    registerExtractByTransaction(transactionId: $transactionId) {
      titles {
        titleNumber
        tenure
        address
      }
      existingTitles {
        titleNumber
      }
      hasTitles
    }
  }
`;

export const PROPERTY_PACK_STATUS = gql`
  query propertyStatus($transactionId: String!) {
    propertyStatus(transactionId: $transactionId) {
      status
      displayPublishBanner
      validation {
        error
        status
        overlay
      }
    }
  }
`;

export const TRANSACTION_PROPERTY_NTS_DATA = gql`
  query propertyNtsData($transactionId: String!) {
    propertyNtsData(transactionId: $transactionId) {
      title
      slug
      groups {
        title
        slug
        entries {
          title
          value
          slug
        }
      }
    }
  }
`;

export const TRANSACTION_PROPERTY_ALL_DATA = gql`
  query propertyAllData($transactionId: String!) {
    propertyAllData(transactionId: $transactionId) {
      title
      slug
      formId
      groups {
        title
        slug
        entries {
          title
          value
          slug
        }
      }
    }
  }
`;

export const TRANSACTION_REQUESTED_DOCUMENTS = gql`
  query transactionRequestedDocuments($transactionId: String!) {
    transactionRequestedDocuments(transactionId: $transactionId) {
      title
      description
      imageUrl
      externalUrl
      document {
        domain
        documentId
        created
        key
        status
        fileName
        displayName
        description
        mimeType
        documentType
        ownerId
        ownerName
        presignedUrl
        file
      }
    }
  }
`;

export const CUSTOMER_DOCUMENTS = gql`
  query customerDocuments {
    customerDocuments {
      domain
      created
      key
      status
      fileName
      displayName
      description
      mimeType
      documentType
      ownerId
      ownerName
      presignedUrl
      file
      documentId
    }
  }
`;

export const TRANSACTION_DOCUMENTS = gql`
  query transactionDocuments($transactionId: String!) {
    transactionDocuments(transactionId: $transactionId) {
      domain
      created
      key
      status
      fileName
      displayName
      description
      mimeType
      documentType
      ownerId
      ownerName
      presignedUrl
      file
    }
  }
`;

export const TRANSACTION_SUMMARY_V2 = gql`
  query transactionSummary($transactionId: UUID!) {
    transactionV1(transactionId: $transactionId) {
      transactionId
      type
      status
      imageUrl
      address
      shortAddress
      created
    }
  }
`;

export const TRANSACTIONS_SUMMARY_V2 = gql`
  query transactionsSummary {
    transactionsV1 {
      transactionId
      type
      status
      imageUrl
      address
      shortAddress
      created
    }
  }
`;

export const TRANSACTIONS_FUNDS = gql`
  query transactionFunds($transactionId: UUID!) {
    transactionV1Funds(transactionId: $transactionId) {
      funds {
        transactionFundId
        transactionId
        type
        subtype
        detail
        amount
        rate
        created
        deleted
        userName
        userId
        evidenceType
        evidenceRef
      }
    }
  }
`;

export const TRANSACTIONS_FUNDS_TOTAL = gql`
  query transactionFundsTotal($transactionId: UUID!) {
    transactionV1Funds(transactionId: $transactionId) {
      fundsTotal
      funds {
        transactionFundId
        transactionId
        type
        subtype
        detail
        amount
        rate
        created
        deleted
        userName
        userId
        evidenceType
        evidenceRef
        document {
          status
        }
      }
      offers {
        offerId
        companyImageUrl
        propertyImageUrl
        companySlug
        amount
        status
        buyingTransactionId
        address
        substantiations {
          status
        }
      }
    }
  }
`;

export const FETCH_DOCUMENT = gql`
  query fetchDocument($documentId: UUID!, $contentDisposition: String) {
    fetchDocument(
      documentId: $documentId
      contentDisposition: $contentDisposition
    ) {
      domain
      documentId
      created
      key
      status
      fileName
      displayName
      description
      mimeType
      documentType
      ownerId
      ownerName
      presignedUrl
      file
    }
  }
`;

export const TRANSACTION_FUNDS_REQUESTED_DOCUMENTS = gql`
  query transactionFundsRequestedDocuments($transactionId: String!) {
    transactionFundsRequestedDocuments(transactionId: $transactionId) {
      title
      description
      imageUrl
      externalUrl
      document {
        domain
        documentId
        created
        key
        status
        fileName
        displayName
        description
        mimeType
        documentType
        ownerId
        ownerName
        presignedUrl
        file
      }
    }
  }
`;

export const TRANSACTION_OFFERS = gql`
  query transactionOffers($transactionId: UUID!) {
    transactionOffers(transactionId: $transactionId) {
      offerId
      companyImageUrl
      propertyImageUrl
      companySlug
      amount
      status
      buyingTransactionId
      address
      created
      substantiations {
        offerSubstantiationId
        status
        userId
        userName
        created
        totalAmount
        funds {
          type
          subtype
          amount
          rate
          evidenceType
          evidenceRef
          created
          userName
          userId
          document {
            status
          }
        }
      }
    }
  }
`;

export const TASK_LIST = gql`
  query taskList {
    taskList {
      taskCount
      user {
        taskId
        name
        image
        meta
        status
        owner
        company
        companyImageUrl
        domain
        domainImageUrl
        slug
        category
        predecessor
      }
      transactions {
        transactionId
        address
        tasks {
          taskId
          name
          image
          meta
          status
          owner
          company
          companyImageUrl
          domain
          domainImageUrl
          slug
          category
          predecessor
        }
      }
    }
  }
`;

export const CASE_PUBLIC = gql`
  query casePublic($caseId: UUID!, $email: String) {
    casePublic(caseId: $caseId, email: $email) {
      caseId
      type
      status
      imageUrl
      address
      shortAddress
      created
      inProgressUser
      userStatus
      transaction {
        transactionId
        type
        status
        imageUrl
        address
        shortAddress
        created
      }
      company {
        slug
        tradingName
        imageUrl
        settings {
          colourMain
          colourDark
          colourLight
          colourContrast
        }
      }
    }
  }
`;

export const TRANSACTION_PUBLIC = gql`
  query transactionPublic($transactionId: UUID!, $email: String) {
    transactionPublicV1(transactionId: $transactionId, email: $email) {
      transactionId
      type
      status
      imageUrl
      address
      shortAddress
      created
      userStatus
      property {
        line1
        line2
        town
        postcode
        address
      }
      team {
        companySlug
        role
        company {
          slug
          tradingName
          imageUrl
          privacyPolicyLink
          settings {
            colourMain
            colourDark
            colourLight
            colourContrast
          }
        }
      }
    }
  }
`;

export const CASE = gql`
  query case($caseId: UUID!) {
    case(caseId: $caseId) {
      caseId
      type
      status
      imageUrl
      address
      shortAddress
      created
      inProgressUser
      userStatus
      transaction {
        transactionId
        type
        status
        imageUrl
        address
        shortAddress
        created
      }
      company {
        slug
        tradingName
        imageUrl
        settings {
          colourMain
          colourDark
          colourLight
          colourContrast
        }
      }
      property {
        line1
        line2
        town
        postcode
        address
        uprn
      }
    }
  }
`;
