'use client';

import {
  TeamSummary,
  TransactionSummary,
  WelcomeHeader,
} from '@/components/_v2';
import TaskWidget from '@/components/_v2/TaskWidget/TaskWidget';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

/**
 * Page: Dashboard Page
 */
export default function Dashboard() {
  const isXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  return (
    <>
      <Stack direction={isXs ? 'column' : 'row'}>
        <TaskWidget />
        <Stack
          sx={{
            gap: 2,
            height: isXs ? '100%' : ['calc(100svh)', 'calc(100vh)'],
            overflowY: 'auto',
            px: { xs: 1, sm: 4 },
            pt: { xs: 1, sm: 6 },
            pb: 8,
            flexGrow: 1,
            minWidth: '200px',
          }}
        >
          <WelcomeHeader />
          {/* <ToDo /> */}
          <TransactionSummary />
          <TeamSummary />
        </Stack>
      </Stack>
    </>
  );
}
