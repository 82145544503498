import { getHelp } from '@/utils/helpers';
import QuestionMark from '@mui/icons-material/QuestionMark';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

function NoTasks() {
  return (
    <Paper elevation={0} sx={{ mr: 1 }}>
      <Stack p={2} gap={2}>
        <Typography variant="h6" color="white.main">
          You&#39;re up to date
        </Typography>
        <Typography variant="body2" color="white.main">
          We&#39;ll let you know when you&#39;ve got new tasks to complete.
        </Typography>
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            endIcon={<QuestionMark />}
            onClick={() => {
              getHelp('get-help');
            }}
          >
            Get help
          </Button>
        </Box>
      </Stack>
    </Paper>
  );
}

export default NoTasks;
