import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Task, Transaction } from '../taskWidgetTypes';
import TaskWidgetTask from './Task';
import TaskWidgetTaskGroup from './TaskGroup';

type Props = {
  transactions: Transaction[];
  isSingleTask?: boolean;
  refetch: () => void;
};

const areAllTasksComplete = (transaction: Transaction): boolean => {
  const allTasksComplete = transaction.tasks.every(
    (task) => task.status === 'complete'
  );
  const allTaskGroupsComplete = transaction.taskGroup.every((group) =>
    group.tasks.every((task) => task.status === 'complete')
  );
  return allTasksComplete && allTaskGroupsComplete;
};

const TaskList = ({ transactions, isSingleTask, refetch }: Props) => {
  if (isSingleTask) {
    const firstValidTransaction = transactions.find(
      (transaction) =>
        transaction?.tasks?.length > 0 || transaction?.taskGroup?.length > 0
    );

    if (!firstValidTransaction || areAllTasksComplete(firstValidTransaction))
      return null;

    const transactionTasks = firstValidTransaction.tasks.filter(
      (task: Task) => task.status !== 'complete'
    );
    const task =
      transactionTasks.length > 0
        ? transactionTasks[0]
        : firstValidTransaction.taskGroup[0];

    if (!task) return null;

    return (
      <Stack
        key={firstValidTransaction.transactionId}
        gap={1}
        sx={{ width: '100%', pr: 1 }}
      >
        <Typography variant="h6" color="white.main" px={1} noWrap>
          {firstValidTransaction.address}
        </Typography>
        {task.category ? (
          <TaskWidgetTaskGroup
            key={task.category}
            taskGroup={task}
            refetchTaskWidget={refetch}
          />
        ) : (
          <TaskWidgetTask
            key={task.taskId}
            task={task}
            refetchTaskWidget={refetch}
          />
        )}
      </Stack>
    );
  }

  return (
    <Stack gap={3}>
      {transactions.map((transaction) => (
        <>
          {!areAllTasksComplete(transaction) && (
            <Stack
              key={transaction.transactionId}
              gap={1}
              sx={{ width: '100%', pr: 1 }}
            >
              <Typography variant="h6" color="white.main" px={1} noWrap>
                {transaction.address}
              </Typography>
              {transaction.tasks.map((task) => (
                <TaskWidgetTask
                  key={task.taskId}
                  task={task}
                  refetchTaskWidget={refetch}
                />
              ))}
              {transaction.taskGroup.map((taskGroup) => (
                <TaskWidgetTaskGroup
                  key={taskGroup.category}
                  taskGroup={taskGroup}
                  refetchTaskWidget={refetch}
                />
              ))}
            </Stack>
          )}
        </>
      ))}
    </Stack>
  );
};

export default TaskList;
