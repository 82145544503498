'use client';

import Task from '@/components/_v2/Task/Task';
import { useCanvas } from '@/context/CanvasContextProvider';
import { TaskContextProvider } from '@/context/TaskContextProvider';
import { TaskGroupContextProvider } from '@/context/TaskGroupContextProvider';
import { TASK_LIST } from '@/services/queries';
import { getTaskMetaUrl, isTaskRedirect } from '@/utils/helpers';
import { useQuery } from '@apollo/client';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import RefreshIcon from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useRouter } from 'next/navigation';
import { useState } from 'react';
import TaskGroupV2 from '../TaskGroup/TaskGroupV2';
import NoTasks from './components/NoTasks';
import TaskList from './components/TaskList';
import TaskListUser from './components/TaskListUser';
import TaskLoader from './components/TaskLoader';
import {
  CategorizedTasks,
  TaskGroupMapping,
  Transaction,
} from './taskWidgetTypes';

const TASK_GROUP_MAPPING: { [key: string]: TaskGroupMapping } = {
  'property-forms': {
    title: 'Add property information',
    description:
      "Provide the property information your team needs before they're allowed to put it on the market.",
    image:
      'https://kotini-public.s3.eu-west-1.amazonaws.com/images/task/property-forms.png',
  },
  'property-evidence': {
    title: 'Upload property documents',
    description:
      'Upload all the evidence needed to accompany the property information you’ve provided.',
    image:
      'https://kotini-public.s3.eu-west-1.amazonaws.com/images/task/property-evidence.png',
  },
  'funds-evidence': {
    title: 'Upload evidence of your funds',
    description:
      'Upload all the evidence needed to prove you have the funds available for your property purchase.',
    image:
      'https://kotini-public.s3.eu-west-1.amazonaws.com/images/task/funds-evidence.png',
  },
  'selling-agent-marketing': {
    title: 'Add marketing preferences',
    description:
      'Provide the general marketing information and make your preferences for things like sales boards and viewings known to your agents.',
    image:
      'https://d3g69anopyhjxa.cloudfront.net/images/task/selling-agent-marketing.png',
  },
};

const categorizeTasks = (transactions: Transaction[]) =>
  transactions.map(({ tasks, address, transactionId }) => {
    const categorizedTasks = tasks.reduce<CategorizedTasks>(
      (acc, task) => {
        const { category } = task;
        if (category) {
          if (!acc.categorized[category]) {
            acc.categorized[category] = {
              category,
              transactionId,
              tasks: [],
              ...TASK_GROUP_MAPPING[category],
            };
          }
          acc.categorized[category] = {
            ...acc.categorized[category],
            tasks: [...acc.categorized[category].tasks, task],
          };
        } else {
          acc.uncategorized = [...acc.uncategorized, task];
        }
        return acc;
      },
      { categorized: {}, uncategorized: [] }
    );

    return {
      address,
      transactionId,
      tasks: categorizedTasks.uncategorized,
      taskGroup: Object.values(categorizedTasks.categorized),
    };
  });

const filterPendingTasks = (tasks: any[]) =>
  tasks.filter((task) => task.status !== 'complete');

const getPriorityTask = (
  userPendingTasks: any[],
  transactionPendingTasks: any[],
  transactionPendingTaskGroup: any
) => {
  if (userPendingTasks.length > 0) return userPendingTasks[0];
  if (transactionPendingTasks.length > 0) return transactionPendingTasks[0];
  return transactionPendingTaskGroup;
};

function TaskWidget() {
  const router = useRouter();
  const isXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
  const [seeAllTasks, setSeeAllTasks] = useState(isXs ? true : false);
  const { setShowCanvas, setCanvasContent, setShowCanvasHeader } = useCanvas();

  const { data, loading, error, refetch } = useQuery(TASK_LIST, {
    fetchPolicy: 'cache-and-network',
  });

  const theme = useTheme();

  const taskCount = data?.taskList?.taskCount;
  const userTasks = data?.taskList?.user;
  const transactionTasks = data?.taskList?.transactions;

  const handleClose = () => {
    setShowCanvas(false);
  };

  const handleNextTask = () => {
    const userPendingTasks = filterPendingTasks(userTasks);
    const categorizedTransactionTasks = categorizeTasks(transactionTasks);
    const firstValidTransaction = categorizedTransactionTasks.find(
      (transaction) =>
        transaction?.tasks?.length > 0 || transaction?.taskGroup?.length > 0
    );
    const transactionPendingTasks = filterPendingTasks(
      firstValidTransaction?.tasks || []
    );
    const transactionPendingTaskGroup = firstValidTransaction?.taskGroup[0];

    const priorityTask = getPriorityTask(
      userPendingTasks,
      transactionPendingTasks,
      transactionPendingTaskGroup
    );
    if (isTaskRedirect(priorityTask)) {
      router.push(getTaskMetaUrl(priorityTask));
    } else if (!priorityTask?.category) {
      setCanvasContent(
        <TaskContextProvider>
          <Task
            task={priorityTask}
            refetchTodo={refetch}
            onClose={handleClose}
          />
        </TaskContextProvider>
      );
    } else {
      setCanvasContent(
        <TaskContextProvider>
          <TaskGroupContextProvider>
            <TaskGroupV2 taskGroup={priorityTask} onClose={handleClose} />
          </TaskGroupContextProvider>
        </TaskContextProvider>
      );
    }

    setShowCanvasHeader(false);
    setShowCanvas(true);
  };

  const generateTaskWidgetInner = () => {
    if (loading || !data) return <TaskLoader />;

    if (taskCount === 0) return <NoTasks />;
    const categorizedTransactionTasks = categorizeTasks(transactionTasks);

    if (isXs && !seeAllTasks) {
      if (userTasks.length > 0)
        return <TaskListUser tasks={[userTasks[0]]} refetch={refetch} />;
      return (
        <TaskList
          transactions={categorizedTransactionTasks}
          isSingleTask
          refetch={refetch}
        />
      );
    }

    return (
      <Stack gap={3} sx={{ width: '100%' }}>
        <TaskListUser tasks={userTasks} refetch={refetch} />
        <TaskList
          transactions={categorizedTransactionTasks}
          refetch={refetch}
        />
      </Stack>
    );
  };

  return (
    <Stack
      sx={{
        p: 2,
        pr: 1,
        my: 2,
        bgcolor: 'primary.main',
        borderRadius: 4,
        gap: 2,
        width: isXs ? '100%' : '360px',
        minWidth: isXs ? '100%' : '360px',
        height: isXs ? '100%' : ['calc(100svh - 32px)', 'calc(100vh - 32px)'],
      }}
    >
      <Stack direction="row" sx={{ gap: 1, alignItems: 'flex-end' }}>
        <Typography
          variant="h4"
          sx={{
            opacity: loading ? 0.1 : 1,
            color: '#ffffff !important',
            pl: 2,
          }}
        >
          Tasks
        </Typography>
        {!loading && (
          <IconButton color="white" size="small" onClick={() => refetch()}>
            <RefreshIcon />
          </IconButton>
        )}
      </Stack>
      <Stack
        gap={3}
        sx={{
          height: '100%',
          flex: isXs ? '1' : '1 0 0',
          overflowY: 'auto',
          alignItems: 'flex-start',
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': { m: '8px' },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.contrastText + '33',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: theme.palette.primary.contrastText + '44',
          },
        }}
      >
        {generateTaskWidgetInner()}
      </Stack>
      {!loading && data && (
        <>
          {!isXs && (
            <Typography variant="body1" color="white.main" textAlign={'center'}>
              {`${taskCount.toString()} left to do`}
            </Typography>
          )}
          <Stack
            gap={1}
            sx={{
              flexDirection: isXs ? 'row' : 'column',
              pr: 1,
            }}
          >
            {isXs && (
              <Button
                variant="text"
                color="white"
                size="large"
                onClick={() => setSeeAllTasks(!seeAllTasks)}
                endIcon={seeAllTasks ? <ExpandLess /> : <ExpandMore />}
                fullWidth
              >
                {seeAllTasks
                  ? 'See fewer'
                  : `All ${taskCount.toString()} tasks`}
              </Button>
            )}

            <Button
              variant="contained"
              color="success"
              size="large"
              onClick={handleNextTask}
              endIcon={<ChevronRight />}
              fullWidth
            >
              Next task
            </Button>
          </Stack>
        </>
      )}
    </Stack>
  );
}

export default TaskWidget;
