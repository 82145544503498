'use client';

import React from 'react';

type Action =
  | { type: 'SET_TASKS'; payload: any[] }
  | { type: 'SELECT_TASK'; payload: any }
  | { type: 'TOGGLE_COMPLETED' }
  | { type: 'CLOSE_TASK' }
  | { type: 'COMPLETE_NEXT_TASK' };

type Dispatch = (action: Action) => void;

type State = {
  tasks: any[];
  currentTask: any;
  nextTask: any;
  lastTask: any;
  showCompleted: boolean;
  isTaskOpen: boolean;
};

type TaskGroupProviderProps = { children: React.ReactNode };

const TaskGroupStateContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function taskGroupReducer(state: State, action: Action) {
  switch (action.type) {
    case 'SET_TASKS': {
      const incompleteTasks = action.payload.filter(
        (task) => task.status === 'pending'
      );
      return {
        ...state,
        tasks: action.payload,
        nextTask: incompleteTasks.length === 0 ? null : incompleteTasks[0],
      };
    }
    case 'SELECT_TASK': {
      const nextTaskIndex =
        state.tasks.findIndex((task) => task.taskId === action.payload.taskId) +
        1;
      return {
        ...state,
        currentTask: action.payload,
        nextTask: state.tasks[nextTaskIndex],
        lastTask: null,
        isTaskOpen: true,
      };
    }
    case 'CLOSE_TASK': {
      const nextTaskIndex =
        state.tasks.findIndex(
          (task) => task.taskId === state?.currentTask?.taskId
        ) + 1;
      return {
        ...state,
        currentTask: null,
        nextTask: state.tasks[nextTaskIndex],
        lastTask: state.currentTask,
        isTaskOpen: false,
      };
    }
    case 'TOGGLE_COMPLETED': {
      return {
        ...state,
        showCompleted: !state.showCompleted,
      };
    }
    case 'COMPLETE_NEXT_TASK': {
      return {
        ...state,
        tasks: state.tasks.map((task) => {
          if (task.taskId === state.nextTask.taskId) {
            return { ...task, status: 'complete' };
          }
          return task;
        }),
      };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
}

function TaskGroupContextProvider({ children }: TaskGroupProviderProps) {
  const [state, dispatch] = React.useReducer(taskGroupReducer, {
    tasks: [],
    currentTask: null,
    nextTask: null,
    lastTask: null,
    showCompleted: true,
    isTaskOpen: false,
  });
  const value = { state, dispatch };

  return (
    <TaskGroupStateContext.Provider value={value}>
      {children}
    </TaskGroupStateContext.Provider>
  );
}

function useTaskGroup() {
  const context = React.useContext(TaskGroupStateContext);
  if (context === undefined) {
    throw new Error('useTask must be used within a TaskProvider');
  }
  return context;
}

export { TaskGroupContextProvider, useTaskGroup };
