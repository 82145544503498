'use client';

import { getHelp } from '@/utils/helpers';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

type Props = {
  children: React.ReactNode;
  title: string;
  helpDataTestId?: string;
  onClose?: () => void;
};

function CanvasV2({
  children,
  title,
  helpDataTestId = 'get-help',
  onClose,
}: Props) {
  //this can be controlled by a context provider if we need to as of now it is just a local state
  const [showCanvas, setShowCanvas] = useState<boolean>(true);
  const [showClose, setShowClose] = useState<boolean>(false);

  const CloseDialog = () => {
    return (
      <Dialog open={showClose}>
        <DialogTitle>Close task</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to close? You may lose unsaved progress in
            this task.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            size="medium"
            endIcon={<ChevronLeftIcon />}
            onClick={() => setShowClose(false)}
            data-testid="back"
          >
            Go back
          </Button>
          <Button
            size="medium"
            color="error"
            endIcon={<CloseIcon />}
            onClick={() => {
              setShowClose(false);
              onClose && onClose();
            }}
            data-testid="close-canvas-dialog"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const CloseButton = () => {
    return (
      <Button
        variant="text"
        color="primary"
        size="medium"
        startIcon={<CloseIcon />}
        onClick={() => setShowClose(true)}
        data-testid="close-canvas"
      >
        Close
      </Button>
    );
  };

  const HelpButton = () => {
    return (
      <Button
        variant="text"
        color="primary"
        size="medium"
        startIcon={<HelpOutlineIcon />}
        onClick={() => getHelp(helpDataTestId)}
        data-testid="get-help"
      >
        Help
      </Button>
    );
  };

  if (showCanvas)
    return (
      <Paper
        elevation={0}
        sx={{
          width: '100%',
          height: '100%',
          borderRadius: 3,
          maxHeight: ['calc(100svh - 32px)', 'calc(100vh - 32px)'],
        }}
      >
        <Stack
          sx={{
            p: { xs: 1, sm: 2 },
            pt: 2,
            pb: 1,
            width: '100%',
            height: '100%',
            gap: 1,
          }}
        >
          <Stack
            direction="row"
            sx={{
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexGrow: 0,
              px: { xs: 1, sm: 0 },
            }}
          >
            <HelpButton />
            <Typography
              variant="h6"
              color="primary"
              sx={{ textAlign: 'center' }}
              noWrap
            >
              {title}
            </Typography>
            {onClose ? <CloseButton /> : <Box width={100}> </Box>}
          </Stack>
          <Stack sx={{ flexGrow: 1, height: '1px' }}>{children}</Stack>
          <CloseDialog />
        </Stack>
      </Paper>
    );
}

export default CanvasV2;
