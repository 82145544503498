'use client';

import React from 'react';

type Action =
  | { type: 'BACK' }
  | { type: 'NEXT'; payload: any }
  | { type: 'SET_TOTAL_STEPS'; payload: number }
  | { type: 'SET_ACTIVE_STEP'; payload: number }
  | { type: 'SET_IS_LOADING'; payload: boolean }
  | { type: 'SET_IS_COMPLETE'; payload: boolean }
  | { type: 'SET_IS_SUBMITTING'; payload: boolean }
  | { type: 'SET_IS_CANVAS_OPEN'; payload: boolean }
  | { type: 'SET_FORM_VALUES'; payload: any }
  | { type: 'SET_CANVAS_CONTENT'; payload: any }
  | { type: 'SUBMIT'; payload: { input: any; mutation: () => void } }
  | { type: 'RESET' };

type Dispatch = (action: Action) => void;

type State = {
  activeStep: number;
  totalSteps: number;
  isLoading: boolean;
  isComplete: boolean;
  isSubmitting: boolean;
  isCanvasOpen: boolean;
  formValues: any;
  canvasContent: React.ReactNode;
  waitDuration: number;
};

type TaskProviderProps = { children: React.ReactNode };

const TaskStateContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function taskReducer(state: State, action: Action) {
  switch (action.type) {
    case 'BACK': {
      return { ...state, activeStep: state.activeStep - 1 };
    }
    case 'NEXT': {
      return {
        ...state,
        activeStep: state.activeStep + 1,
        formValues: { ...state.formValues, ...action.payload },
      };
    }
    case 'SET_TOTAL_STEPS': {
      return { ...state, totalSteps: action.payload };
    }
    case 'SET_ACTIVE_STEP': {
      return { ...state, activeStep: action.payload };
    }
    case 'SET_IS_LOADING': {
      return { ...state, isLoading: action.payload };
    }
    case 'SET_IS_COMPLETE': {
      return { ...state, isComplete: action.payload };
    }
    case 'SET_IS_SUBMITTING': {
      return { ...state, isSubmitting: action.payload };
    }
    case 'SET_IS_CANVAS_OPEN': {
      return { ...state, isCanvasOpen: action.payload };
    }
    case 'SET_FORM_VALUES': {
      return {
        ...state,
        formValues: { ...state.formValues, ...action.payload },
      };
    }
    case 'SET_CANVAS_CONTENT': {
      return {
        ...state,
        canvasContent: action.payload,
      };
    }
    case 'SUBMIT': {
      return { ...state, activeStep: state.activeStep - 1 };
    }
    case 'RESET': {
      return {
        ...state,
        activeStep: 1,
        totalSteps: 1,
        isLoading: false,
        isComplete: false,
        isSubmitting: false,
        isCanvasOpen: false,
        formValues: {},
        canvasContent: <></>,
      };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
}

function TaskContextProvider({ children }: TaskProviderProps) {
  const [state, dispatch] = React.useReducer(taskReducer, {
    activeStep: 1,
    totalSteps: 1,
    isLoading: false,
    isComplete: false,
    isSubmitting: false,
    isCanvasOpen: false,
    formValues: {},
    canvasContent: <></>,
    waitDuration: 4500, // wait duration in ms
  });
  const value = { state, dispatch };

  return (
    <TaskStateContext.Provider value={value}>
      {children}
    </TaskStateContext.Provider>
  );
}

function useTask() {
  const context = React.useContext(TaskStateContext);
  if (context === undefined) {
    throw new Error('useTask must be used within a TaskProvider');
  }
  return context;
}

export { TaskContextProvider, useTask };
